@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  max-width: calc(100vw - 48px);
  
  
  @media (--viewport1280) {
    gap: 14px;
    justify-content: space-between;
  }

  & > :nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: -20px;
    
    @media (--viewport1280) {
      margin-bottom: -32px;
    }
  }

  & > :nth-child(2) {
    flex: 0 0 auto;
    max-width: auto;
  }

  & > :nth-child(3),
  & > :nth-child(4),
  & > :nth-child(5),
  & > :nth-child(6),
  & > :nth-child(7),
  & > :nth-child(8),
  & > :nth-child(9) {
    flex: 0 0 auto;
    max-width: auto;
    & p {
      display: none;
    }

  }

  & > :nth-child(2),
  & > :nth-child(3),
  & > :nth-child(4),
  & > :nth-child(5),
  & > :nth-child(6),
  & > :nth-child(7),
  & > :nth-child(8),
  & > :nth-child(9) {
    width: auto;

    @media (--viewport1280) {
      width: calc((100%/7) - 14px);
    }
  }

  @media (--viewportCustom) {
    & > :nth-child(2) {
      min-width: 200px;
    }
    
    & > :nth-child(3) {
      min-width: 160px;
    }
    
    & > :nth-child(4) {
      max-width: 95px;
    }
    
    & > :nth-child(5) {
      min-width: 200px;
    }
    
    & > :nth-child(6) {
      max-width: 120px;
    }
    
    & > :nth-child(7) {
      max-width: 160px;
    }
  
    & > :nth-child(8) {
      min-width: 220px;
    }
  }

  @media (--viewport1600) {
    & > :nth-child(2),
    & > :nth-child(3),
    & > :nth-child(5),
    & > :nth-child(8) {
      min-width: unset;
    }

    & > :nth-child(4),
    & > :nth-child(6),
    & > :nth-child(7) {
      max-width: unset;
    }
  }
  
  & > *:not(:last-child) {
    margin-right: 14px;
    height: auto;

    @media (--viewport1280) {
      margin-right: 0;
    }
  }
}

.iconDown {
  display: none;
}

.button {
  border: 1px solid #7B8084;
  border-radius: 30px;
  background: var(--marketplaceColor);
  color: #7B8084;
  height: 36px;
  font-family: var(--fontFamily);
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5px;
  width: 100%;
  text-align: left;
}

.iconMoreFilters {
  position: relative;
  margin-right: 5px;
  top: -2px;
}
